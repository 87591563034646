<template>
  <div class="products">
    <div class="page-header">
      <h1>{{ $t('Delivery Management')}} > {{ $t('Delivery Staffs') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Delivery Staffs') }}</h2>
        <el-button class="action-button" type="primary">{{ $t('Create') }}</el-button>
      </div>

      <div class="promotions-list">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ $t('ID') }}</th>
              <th scope="col">{{ $t('Staff Name') }}</th>
              <th scope="col">{{ $t('Phone') }}</th>
              <th scope="col">統計（評價／訂單數）</th>
              <th scope="col">{{ $t('Actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>605522302</td>
              <td>林秋生</td>
              <td>69591024</td>
              <td>5.0 / 10</td>
              <td>
                <div class="action-links">
                  <a class="action-link" href="#">
                    {{ $t('Edit') }}
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td>402653315</td>
              <td>李海人</td>
              <td>92492035</td>
              <td>5.0 / 1</td>
              <td>
                <div class="action-links">
                  <a class="action-link" href="#">
                    {{ $t('Edit') }}
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td>32363424</td>
              <td>蘇桉橋</td>
              <td>67266593</td>
              <td>5.0 / 5</td>
              <td>
                <div class="action-links">
                  <a class="action-link" href="#">
                    {{ $t('Edit') }}
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td>123646524</td>
              <td>葛洛言</td>
              <td>54902161</td>
              <td>3.0 / 1</td>
              <td>
                <div class="action-links">
                  <a class="action-link" href="#">
                    {{ $t('Edit') }}
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td>761428628</td>
              <td>呂銘蔚</td>
              <td>99464235</td>
              <td>4.5 / 7</td>
              <td>
                <div class="action-links">
                  <a class="action-link" href="#">
                    {{ $t('Edit') }}
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td>634844615</td>
              <td>康凱芝</td>
              <td>68662057</td>
              <td>0 / 0</td>
              <td>
                <div class="action-links">
                  <a class="action-link" href="#">
                    {{ $t('Edit') }}
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td>402653315</td>
              <td>賴卓言</td>
              <td>51648762</td>
              <td>4.0 / 3</td>
              <td>
                <div class="action-links">
                  <a class="action-link" href="#">
                    {{ $t('Edit') }}
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td>355639588</td>
              <td>盧君沂</td>
              <td>98064716</td>
              <td>4.5 / 3</td>
              <td>
                <div class="action-links">
                  <a class="action-link" href="#">
                    {{ $t('Edit') }}
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ItemFilter from '@/components/Filter.vue';
import Common from '@/lib/common';
import Member from '@/lib/member';

export default {
  name: 'Members',
  components: {
    ItemFilter,
  },
  mounted(){
    this.loadAllMembers();
  },
  methods:{
    async loadAllMembers(){
      try{
        const loginInfo = Common.getLoginInfo();
        const members = await Member.loadAllMembers(this.apiUrl, loginInfo);
        this.members = members;
      }catch(err){
        console.log(err);
      }
    },
  },
  data(){
    return {
      members: [],
      orignalMembers: [],
    }
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.seat-list{
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
  .card{
    margin-bottom: 30px;
    margin-right: 20px;
    .btn-primary{
      margin-right: 30px;
    }
  }
}
</style>
